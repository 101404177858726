import React, { Component } from 'react';

import './risk-min-tools.scss';
import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import Annotation from 'src/components/annotation';
import PDFButton from 'src/components/pdf-button';
import { TranslatableText } from 'src/components/language';

class PatientRiskMinimizationToolsPage extends Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('patient/risk-min-tools');
  }

  render() {
    return (
      <Layout
        title={{
          english: 'Risk Minimization Tools',
          french: 'Documents de réduction des risques',
        }}
        showTab={true}
        className="patient"
      >
        <h2>
          <TranslatableText
            dictionary={{
              english: 'Risk Minimization Tools',
              french: 'Documents de réduction des risques',
            }}
          />
        </h2>
        <div className="buttons-container">
          <ul>
            <li>
              <PDFButton
                href={{
                  english: 'https://www.rochecanada.com/documents/Hemlibra/Hemlibra_RiskMin_PatientCarerGuide_EN.pdf',
                  french: 'https://www.rochecanada.com/documents/Hemlibra/Hemlibra_RiskMin_PatientCarerGuide_FR.pdf',
                }}
                textDict={{
                  english: 'Patient/Carer Guide',
                  french: 'Guide du patient/soignant',
                }}
              />
            </li>
            <li>
              <PDFButton
                href={{
                  english: 'https://www.rochecanada.com/documents/Hemlibra/Hemlibra_RiskMin_PatientAlertCard_EN.pdf',
                  french: 'https://www.rochecanada.com/documents/Hemlibra/Hemlibra_RiskMin_PatientAlertCard_FR.pdf',
                }}
                textDict={{
                  english: 'Patient Alert Card',
                  french: 'Carte d’alerte du patient',
                }}
              />
            </li>
          </ul>
        </div>
        <Annotation />
      </Layout>
    );
  }
}

export default PatientRiskMinimizationToolsPage;
