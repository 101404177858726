import React from 'react';

import './annotation.scss';

import { TranslatedBlock } from './language';

const Annotation = ({ fairBalance, hideEducationalPurpose }) => {
  if (!hideEducationalPurpose) {
    var educationalPurpose = (
      <>
        <TranslatedBlock language="english">
          This website is for educational purposes only. It is not intended to replace the advice and guidance of your
          healthcare team. If you have questions about HEMLIBRA treatment or your condition, talk to your doctor.
          <br />
          <br />
        </TranslatedBlock>
        <TranslatedBlock language="french">
          Ce site Web est offert à des fins éducatives seulement. II ne vise en aucun cas à remplacer l’opinion et les
          conseils de votre équipe de soins de santé. Si vous avez des questions sur le traitement par HEMLIBRA ou sur
          votre état de santé, veuillez consulter votre médecin.
          <br />
          <br />
        </TranslatedBlock>
      </>
    );
  }
  if (fairBalance) {
    return (
      <div className="annotation">
        <TranslatedBlock language="english">
          {educationalPurpose}
          <sup>Pr</sup>HEMLIBRA<sup>®</sup> (emicizumab injection) is indicated for hemophilia A (congenital factor VIII
          deficiency) patients with or without factor VIII inhibitors as routine prophylaxis to prevent bleeding or
          reduce the frequency of bleeding episodes. There is limited clinical experience of HEMLIBRA use in patients
          with mild or moderate hemophilia A.
          <br />
          Please consult the Product Monograph at{' '}
          <a href="https://www.rochecanada.com/PMs/Hemlibra/Hemlibra_PM_E.pdf" target="_blank" rel="noreferrer">
            http://www.rochecanada.com/PMs/Hemlibra/Hemlibra_PM_E.pdf
          </a>
          for contraindications, warnings, precautions, adverse reactions, interactions, dosing, and conditions of
          clinical use. The Product Monograph is also available by calling us at{' '}
          <span className="nowrap">1-888-762-4388</span>.
        </TranslatedBlock>
        <TranslatedBlock language="french">
          {educationalPurpose}
          <sup>Pr</sup>HEMLIBRA<sup>®</sup> (émicizumab injectable) est indiqué en prophylaxie de routine pour prévenir
          les hémorragies ou réduire la fréquence des épisodes hémorragiques chez les patients atteints d’hémophilie A
          (déficit congénital en facteur VIII) présentant ou non des inhibiteurs du facteur VIII. L’expérience clinique
          relative à l’utilisation de HEMLIBRA chez les patients atteints d’hémophilie A légère ou modérée est limitée.
          <br />
          Veuillez consulter la monographie à{' '}
          <a href="https://www.rochecanada.com/PMs_FR/Hemlibra/Hemlibra_PM_F.pdf" target="_blank" rel="noreferrer">
            http://www.rochecanada.com/PMs_FR/Hemlibra/Hemlibra_PM_F.pdf
          </a>{' '}
          pour connaître les renseignements sur les contre-indications, les mises en garde, les précautions, les
          réactions indésirables, les interactions médicamenteuses, la posologie et les conditions d'utilisation
          clinique. Il est également possible de se procurer la monographie en nous téléphonant au{' '}
          <span className="nowrap">1-888-762-4388</span>.
        </TranslatedBlock>
      </div>
    );
  } else {
    return (
      <div className="annotation">
        <TranslatedBlock language="english">
          {educationalPurpose}
          For complete information on HEMLIBRA, consult the Medical Information contained in your HEMLIBRA prescription,
          or call <span className="nowrap">1-888-762-4388</span>.
        </TranslatedBlock>
        <TranslatedBlock language="french">
          {educationalPurpose}
          <p className="left">
            Pour obtenir des renseignements complets sur HEMLIBRA, veuillez consulter les renseignements médicaux joints
            à l’emballage de HEMLIBRA ou composer le <span className="nowrap">1-888-762-4388</span>.
          </p>
        </TranslatedBlock>
      </div>
    );
  }
};

export default Annotation;
