import React from 'react';
import PropTypes from 'prop-types';

import './pdf-button.scss';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import { TranslatableText } from './language';
import { withPrefix } from 'gatsby';

export default class PDFButton extends React.Component {
  static contextType = AppStateContext;

  hrefValue() {
    if (typeof this.props.href === 'object') {
      const language = this.context.language;
      return this.props.href[language] ?? '';
    }

    return this.props.href ?? '';
  }

  render() {
    return (
      <a
        role="button"
        className="pdf-button"
        href={withPrefix(this.hrefValue())}
        target="_blank"
        rel="noopener noreferrer"
      >
        <TranslatableText dictionary={this.props.textDict} />
      </a>
    );
  }
}

PDFButton.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  textDict: PropTypes.object.isRequired,
};
